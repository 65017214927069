<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Marketing BOM">
            <div class="vx-row mb-6" style="width:50%;">
                <div class="vx-col sm:w-1/3 w-full flex items-center" style="margin-bottom: 30px;">
                <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    class="selectExample"
                    v-model="selected.territory"
                    :options="option.territory"
                    :multiple="false"
                    :allow-empty="false"
                    :group-select="false"
                    :max-height="100"
                    :limit="3"
                    placeholder="Type to search"
                    track-by="id"
                    @select="selectedOption"
                    label="name"
                    style="z-index: 99;"
                >
                    <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                        <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
                    </span>
                    </template>

                    <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
                    </div>
                    </template>
                </multiselect>
                </div>
            </div>
            <vs-tabs v-model="selected.tab">

                <vs-tab v-if="this.$route.fullPath == '/kitting-management'" @click="colorx = 'danger'" label="List">
                    <div class="con-tab-ejemplo">
                        <list-kitting :selected="selected" :option="option" @changeTab="selectedTab"></list-kitting>
                    </div>
                </vs-tab>
                <vs-tab  v-if="this.$route.fullPath == '/kitting-management'" @click="colorx = 'danger'" label="Form">
                    <div class="con-tab-ejemplo">
                        <form-kitting  :selected="selected" :option="option" @changeTab="selectedTab"></form-kitting>
                    </div>
                </vs-tab>
                <!--
                <vs-tab  v-if="this.$route.fullPath == '/kitting-management'" @click="colorx = 'danger'" label="Valid">
                    <div class="con-tab-ejemplo">
                        <validation-kitting :selected="selected" :option="option" @changeTab="selectedTab"></validation-kitting>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'danger'" label="Approval">
                    <div class="con-tab-ejemplo">
                        <approval-kitting :selected="selected" :option="option" @changeTab="selectedTab"></approval-kitting>
                    </div>
                </vs-tab>
                <vs-tab  v-if="this.$route.fullPath == '/kitting-management'" @click="colorx = 'danger'" label="Release">
                    <div class="con-tab-ejemplo">
                        <release-kitting :selected="selected" :option="option" @changeTab="selectedTab"></release-kitting>
                    </div>
                </vs-tab>
                -->
                <vs-tab @click="colorx = 'danger'" label="Released">
                    <div class="con-tab-ejemplo">
                        <complete-kitting :selected="selected" :option="option" @changeTab="selectedTab"></complete-kitting>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
         
    </div>
</template>
<script>
import form from "./form/form.vue";
import list from "./list/list.vue";
import validation from "./validation/validation.vue";
import approval from "./approval/approval.vue";
import release from "./release/release.vue";
import complete from "./complete/complete.vue";

export default {
    components:{
        'form-kitting':form,
        'list-kitting':list,
        'validation-kitting':validation,
        'approval-kitting':approval,
        'release-kitting':release,
        'complete-kitting':complete,
    },
    data: () => ({
        option : {
            territory:[]
        },
        selected : {
            territory: {},
            tab : 0,
            itemKit : {},
            isEdit:true
        }
    }),
    watch: {
        selected:{
            territory: function() {
                console.log("selected")
            },
        }
    },
    methods:{
         showOption() {
            this.$http.get("/api/v1/allocation-engine/list/territory").then(
                resp => {
                if(resp.code == 200){
                    this.option.territory = []
                    this.option.territory.push({name:'All'})
                    for(var territory = 0;territory<resp.data.territory.length;territory++){
                    this.option.territory.push(resp.data.territory[territory])
                    }
                    console.log(this.option)
                    this.selected.territory = this.option.territory[0];
                }else{
                    // this.$vs.loading.close();
                    this.$router.push("/");
                }
                }
            );
         },
         selectedOption(option){
             console.log("selectedoption")
             console.log(option)
             this.selected.territory = option
             console.log(this.selected.territory)
         },
         selectedTab(value,option,itemKit,isEdit = false){
             this.selected.territory  = option
             this.selected.itemKit  = itemKit
             this.selected.tab = value
             this.selected.isEdit = isEdit
         }
    },
    mounted(){
        // console.log(this.$route)
        this.showOption()
    }
}
</script>
<style >
    .multiselect__tags {
        min-height: 32px;
        display: block;
        padding: 3px 40px 0 8px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px;
    }
    .multiselect__content-wrapper{
        z-index:100;
        background: #fff;
    }
    </style>