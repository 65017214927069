<template>
    <div class="vx-row">
         <div class="vx-col sm:w-1/2 w-full mb-base">
            <div class="vx-row mb-6" v-if="kitting.ID">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="kitting.code" disabled/>
                </div>
            </div>
            <div class="vx-row mb-6" v-if="kitting.ID">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>SKU Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="kitting.sku_code" disabled/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Name</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input :disabled="!selected.isEdit" class="w-full" v-model="kitting.name"/>
                </div>
            </div>
            <div class="vx-row mb-6"  v-if="false">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Type</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <ul class="centerx">
                    <li>
                        <vs-radio v-model="kitting.type" vs-value="1">Pre Kitting</vs-radio>
                    </li>
                    <li>
                        <vs-radio v-model="kitting.type" vs-value="0">Packing Kitting</vs-radio>
                    </li>
                    </ul>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Description</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-textarea :disabled="!selected.isEdit" counter="255" label="Counter: 255" :counter-danger.sync="counterDanger" v-model="kitting.description" />
                </div>
            </div>
            
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Territory</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <!-- <vs-input class="w-full" v-model="territory" disabled/> -->

                    <multiselect 
                            :multiple="true" 
                            :taggable="true"
                            track-by="name"
                            class="selectExample"
                            v-model="kitting.territory"
                            :options="territory"
                            
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="5"
                            placeholder=" Type to search"
                            label="name"
                            
                        >

                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.code }} {{ dt.option.name }}</span>
                            </span>
                        </template>

                       
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Unit</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <!-- <vs-input class="w-full" v-model="territory" disabled/> -->

                    <multiselect 
                            class="selectExample"
                            v-model="kitting.unit"
                            :options="unit"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="100"
                            :limit="3"
                            placeholder="Type to search"
                            track-by="Name"
                            label="Name"
                            :custom-label="nameOnly"
                        >
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Quantity</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vue-number-input
                        inline
                        controls
                        :min="1"
                        :step="1"
                        size="small"
                        v-model="kitting.quantity"
                        :value="1"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Items</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-button size="small" class="mr-3 mb-2" @click="browseItem()">Browse</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Merchandize Items</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-button size="small" class="mr-3 mb-2" @click="browseItemCosumable()">Browse</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">   
                <div class="vx-col sm:w-1/6 w-full">
                    <h3><span>Items</span></h3>
                </div>             
            </div>
                <div class="vx-row mb-6">   
                <vs-table stripe bordered :data="kitting.items" style="width:100%">
                    <template slot="thead">
                        <vs-th>Code</vs-th>
                        <vs-th>Name</vs-th>
                        <vs-th>Uom</vs-th>
                        <vs-th>Is Main?</vs-th>
                        <vs-th>Qty </vs-th>
                        <vs-th>Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="index" v-for="(dt, index) in data">
                            <vs-td>{{dt.code}}</vs-td>
                            <vs-td>{{dt.name}}</vs-td>
                            <vs-td>{{dt.uom}}</vs-td>
                            <vs-td>
                                <vs-checkbox v-if="dt.from!='consumable'" v-model="kitting.items[index].type_item" @change="test" color="success" >
                                    <!-- <vs-chip color="primary" v-if="kitting.items[index].type_item   ">Main</vs-chip> -->
                                    <!-- <vs-chip v-else color="warning" >Free</vs-chip> -->
                                </vs-checkbox>
                                <vs-chip v-else color="warning">Support</vs-chip>
                            </vs-td>
                            <vs-td>
                                    <vue-number-input
                                        inline
                                        controls
                                        :min="1"
                                        :step="1"
                                        size="small"
                                        v-model="kitting.items[index].quantity"
                                    />
                                </vs-td>
                                <vs-td style="align-item-center">
                                    <vx-tooltip  v-if="selected.isEdit!=false"  text="Delete Item">
                                        <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteItemLine(index,dt)"/>
                                    </vx-tooltip>
                                    <vx-tooltip v-else text="Cant Delete">
                                        <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 stroke-current" class="ml-2" />
                                    </vx-tooltip>
                                </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-6/6 w-full">
                    <vs-button :disabled="!selected.isEdit" class="mr-3 mb-2" @click="SubmitKitting()">Submit</vs-button>
                </div>
            </div>
         </div>
         <div class="vx-col sm:w-1/2 w-full mb-base" >
            <div class="vx-row mb-6">                
                <h3 v-if="itemsCosumable">Item Support</h3>
                <h3 v-else>Item </h3>
            </div>
            <div class="vx-row mb-6">                
                <data-table
                    :responseData="responseData"
                    :propsParams="params"
                    :header="header"
                    @reloadDataFromChild="reloadData" 
                    style="padding-left:10px;width:100%"
                    >
                    <template slot="tbody">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.data">
                        <!-- <vs-td :data="data[indextr].ID">{{data[indextr].ID}}</vs-td> -->
                        <vs-td>
                            {{tr.code}}
                        </vs-td>
                        <vs-td>
                            {{tr.name}}
                        </vs-td>
                        <vs-td>
                            {{tr.uom}}
                        </vs-td>
                        <vs-td> 
                            <vx-tooltip v-if="selected.isEdit!=false" text="Add items">
                                <feather-icon  icon="PlusIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="addItemsToLines(tr)"/>
                            </vx-tooltip>
                            <vx-tooltip  v-else text="Cant Add Item">
                                <feather-icon  icon="PlusIcon" svgClasses="w-5 h-5  stroke-current" class="ml-2" />
                            </vx-tooltip>
                        </vs-td>
                        </vs-tr>
                    </template>
                    </data-table>
            </div>
         </div>
    </div>
</template>
<script>
import VueNumberInput from "@chenfengyuan/vue-number-input";
export default {
    components: {
        VueNumberInput
    },
    props:{
        selected :  Object,
        option : Object
    },
    created(){
         
        for(var t in this.option.territory){
            if(this.option.territory[t].name!='All'){
                 this.territory.push(this.option.territory[t])
            }
        }
        this.$http.get("/api/v1/kitting-management/unit").then(
            resp => {
                if(resp.code == 200){
                    this.unit = resp.data.unit
                    this.kitting.unit = resp.data.unit[0]
                    console.log(this.kitting.unit)
                }
            }
        );
        if(Object.keys(this.selected.itemKit).length>0){
            this.editOrShow()
        }
    },
    destroyed(){
        this.selected.isEdit = true
        this.selected.itemKit = {}
    },
    data:()=>({
        kitting:{
            id : 0,
            code : '',
            name : '',
            territory:[],
            unit:{},
            quantity:1,
            description :'',
            items:[],
            delete_items:[],
        },
        params: {
            search: "",
            length: 10,
            page: 1,
            order: "asc",
            sort: "sku_code"
        },
        header: [
            {
                text: "Code",
                // value: "sku_code"
                sortable: false

            },
            {
                text: "Name",
                // value: "item_name"
                sortable: false
            },
            {
                text: "Unit",
                // value: "name"
                sortable: false
            },
            {
            text: "Action",
            sortable: false
            }
        ],
        responseData: {},
        counterDanger: false,
        indexDelete:'',
        itemsCosumable:false,
        isEditorShow:false,
        territory:[],
        unit:[]
    }),
    computed:{
       
    },
    watch:{
       
    },
    methods:{
        nameOnly({ Name }){
            return `${Name}`
        },
        test(){
            console.log(this.kitting.items)
            
        },
        reloadData(params) {
            this.params = params;
            this.$vs.loading();
            var url = "/api/v1/item-available"
            // this.$http
            //     .get("/api/v1/inventory-container", {
                this.$http.get(url, {
                params: {
                    search: params.search,
                    limit: params.length,
                    offset: params.length* (params.page-1),
                    order: params.sort,
                    sort: params.order,
                    territory_code: this.selected.territory.code,
                    territory_id: this.selected.territory.ID,
                    item_consumable: this.itemsCosumable
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.responseData= {
                        data : resp.data.ItemsAvailable,
                         recordsTotal:  resp.data.RecordsTotal,
                         length : resp.data.ResultsTotal
                    }
                    this.$vs.loading.close();
                } else{
                    this.$vs.loading.close();
                }
            });
        },
        addItemsToLines(items){
            if(!this.selected.isEdit){
                return true
            }
            this.$vs.loading();
            // if(!this.kitting.items.find(function(item)  {
            //     return item.code == items.code
            // })){
            this.kitting.items.push(items)
            var index = this.kitting.items.indexOf(items)
                this.kitting.items[index].quantity = 1
            if(this.itemsCosumable){
                this.kitting.items[index].from = "consumable"
                // this.kitting.items[index].typeItem = false
            }
            else{
                this.kitting.items[index].from = "items"
                this.kitting.items[index].type_item = true
            }
            // }else{
            //     this.$vs.notify({
            //         title:'Error',
            //         text:items.name +" has been add",
            //         color:'danger',
            //         position:'top-right',
            //         iconPack: 'feather',
            //         icon:'icon-alert-circle'})
            // }
                setTimeout( ()=> {
                this.$vs.loading.close()
                }, 1000);
        },
        deleteItemLine(index,dt){
            console.log(this.kitting.items[index])
            this.indexDelete = index
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Confirm`,
                text: 'Are you sure remove '+dt.name+' from lines?',
                accept: this.confirmDelete
            })
        },
        confirmDelete(){
            this.$vs.loading();
            
            setTimeout( ()=> {
                this.$vs.loading.close()
                if(this.kitting.items[this.indexDelete].ID){
                    this.kitting.delete_items.push(this.kitting.items[this.indexDelete])
                }
                this.kitting.items.splice(this.indexDelete, 1);
            }, 1000);

        },
        SubmitKitting(){
            if(this.kitting.items.length < 1 || this.kitting.territory.length < 1){
                this.$vs.notify({
                title: "Danger",
                text: "item and territory can't empty",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check"
                });
                return false
            }
            if(this.isEditorShow){
                this.$http
                    .put("api/v1/kitting-management/store", JSON.stringify(this.kitting))
                    .then(resp => {
                    console.log(resp);
                    if (resp.code == 200) {
                        this.$vs.notify({
                        title: "Success",
                        text: "",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                        });
                        this.selected.itemKit.ID = this.kitting.ID
                        this.editOrShow()
                        this.reloadData(this.params)
                    } else {
                        this.$vs.notify({
                        title: "Danger",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                        });
                    }
                });

            }else{
                this.$http
                    .post("api/v1/kitting-management/store", JSON.stringify(this.kitting))
                    .then(resp => {
                    console.log(resp);
                    if (resp.code == 200) {
                        this.$vs.notify({
                        title: "Success",
                        text: "",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                        });
                        this.selected.itemKit.ID = resp.data.ID
                        this.editOrShow()
                        this.reloadData(this.params)
                    } else {
                        this.$vs.notify({
                        title: "Danger",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                        });
                    }
                });
            }
            
        //   setTimeout(function() {
            this.$vs.loading.close();
            this.$emit("changeTab",0,{name:"All"},{},false)
            console.log("update yaa pindah")
        //   }, 1000);

        },
        browseItemCosumable(){
            this.itemsCosumable = true
            this.reloadData(this.params)
        },
        browseItem(){
            this.itemsCosumable = false
            this.reloadData(this.params)
        },
        editOrShow(){
            this.$vs.loading();
            this.$http.get("api/v1/kitting-management/read",{
                params: {
                    kitting_id:this.selected.itemKit.ID
                }
            }).then(resp => {
                if(resp.code ==200){
                    this.kittingValue(resp.data)
                    this.isEditorShow = true
                }else{
                    // console.log(resp.code)
                    this.$vs.dialog({
                        type: 'alert',
                        color: 'danger',
                        title: `Alert`,
                        text: 'Whoops',
                    })
                }
                this.$vs.loading.close();

            });
        },
        kittingValue(kitting){
            this.kitting.items = []
            this.kitting.delete_items = []
            this.kitting.ID = kitting.itemKit.ID
            this.kitting.territory.push(this.territory.find(function(territory)  {
                return territory.code == kitting.itemKit.TerritoryCode
            }))
            this.kitting.unit = kitting.unit
            this.kitting.code = kitting.itemKit.Code
            this.kitting.sku_code = kitting.itemKit.SkuCode
            this.kitting.name = kitting.itemKit.Name
            this.kitting.description = kitting.itemKit.Description
            for (var x in kitting.itemKitLines) {
                this.kitting.items.push({
                    code : kitting.itemKitLines[x].item.SkuCode,
                    from : "items",
                    item_unit_id : kitting.itemKitLines[x].ItemUnitID,
                    name : kitting.itemKitLines[x].item.Name,
                    quantity : kitting.itemKitLines[x].Quantity,
                    type_item :kitting.itemKitLines[x].ItemType==1?true:false,
                    uom: kitting.itemKitLines[x].unit.Name,
                    id : kitting.itemKitLines[x].DefaultAttribute.ID,
                })
            }
            for (var y in kitting.itemKitSuportLines) {
                this.kitting.items.push({
                    code : kitting.itemKitSuportLines[y].item.SkuCode,
                    from : "consumable",
                    item_unit_id : kitting.itemKitSuportLines[y].ItemUnitID,
                    name : kitting.itemKitSuportLines[y].item.Name,
                    quantity : kitting.itemKitSuportLines[y].Quantity,
                    uom: kitting.itemKitSuportLines[y].unit.Name,
                    id : kitting.itemKitSuportLines[y].DefaultAttribute.ID,
                })
            }
        }   
    }
}
</script>