<template>
  <div>
    <div class="vx-row mb-12">
        <vs-button v-on:click="submitValidation()" icon="done" color="primary">Submit</vs-button>
    </div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        > <template slot="thead">
            <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th>
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.itemKits" v-bind:class="[selectedData.ID==tr.ID ? 'colored' : '']">
              <!-- <vs-td :data="data[indextr].ID">{{data[indextr].ID}}</vs-td> -->
              <vs-td>
                  <vs-checkbox color="success" 
                  :checked="validate.includes(tr.ID)"
                  v-on:click="addValidate(tr.ID)">Release</vs-checkbox>
              </vs-td>
              <vs-td>
                <vs-button
                  v-on:click="clickShow(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                >{{tr.Code}}</vs-button>
                Code : {{tr.Code}}<br>
                Name : {{tr.Name}}<br>
                Desc : {{tr.Description}}<br>
              </vs-td>
              <vs-td>
                Code : {{option.territory.filter(function(territory)  {
                  return territory.ID == tr.TerritoryID;
                })[0].code}} <br>
                Territory : {{option.territory.filter(function(territory)  {
                  return territory.ID == tr.TerritoryID;
                })[0].name}} 
              </vs-td>
              <!-- <vs-td>{{data[indextr].Code}}</vs-td> -->
                <vs-td>
                    Items :  {{tr.Items}}<br>
                    Item Consumables :  {{data[indextr].ItemConsumables}}
                </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>

          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
export default {
    components: {
    },
    props:{
        selected :  Object,
        option : Object
    },
    data() {
        return {
        params: {
            search: "",
            length: 10,
            page: 1,
            order: "asc",
            sort: "code"
        },
        header: [
            {
                text: "Kitting",sortable: false
            },
            {
                text: "Territory",value: "code"
                // width: '5%'
            },
            {
                text: "Total Items",sortable: false
            }
        ],
        responseData: {},
        detailShow: "core vx-col md:w-1/2 w-full mb-base ",
        detailHide: "core vx-col md:w-1/1 w-full mb-base ",
        detail: false,
        selectedData: {},
        validateAll:false,
        validate:[]
        };
    },
    computed:{
        territory:{
            get: function () {
                return "("+this.selected.territory.code+") "+this.selected.territory.name
            }
        }
    },
    watch:{
        territory:function(){
            // this.kitting.territory = this.selected.territory
            this.reloadData(this.params)
        }
    },
    mounted() {
        this.reloadData(this.params);
        
        // this.getData();
        // this.page(1);
        // this.dataId = this.data[0].ID
    },
    methods: {
        clickShow(tr) {
            this.closeDetail();
            setTimeout(function() {}, 3000);
            var option = this.option.territory.filter(function(territory)  {
                  return territory.ID == tr.TerritoryID;
                })[0]
            this.$emit("changeTab",1,option,tr,false)
        },
        closeDetail() {
            this.detail = false;
            this.selectedData = {
                ID : 0
            };
        },
        addValidate(ID){
          console.log(this.validate.length);
          if (this.validate.includes(ID)) {
            this.validate.splice(this.validate.indexOf(ID), 1);
          } else {
            this.validate.push(ID);
          }
        },
        addAllValidate(){
          if (this.validateAll == false) {
            for (var i in this.responseData.itemKits) {
              if (!this.validate.includes(this.responseData.itemKits[i].ID)) {
                this.validate.push(this.responseData.itemKits[i].ID);
              }
            }
          } else {
            for (var j in this.responseData.itemKits) {
              this.validate.splice(this.validate.indexOf(this.responseData.itemKits[j].ID), 1);
            }
          }
        },
        submitValidation(){
          this.$http
              .put("api/v1/kitting-management/status/update", {
                item_kit_ids: this.validate,
                status:  parseInt(3)
              })
              .then(resp => {
              if (resp.code == 200) {
                  this.$vs.notify({
                  title: "Success",
                  text: "",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check"
                  });
                  this.reloadData(this.params)
              } else {
                  this.$vs.notify({
                  title: "Danger",
                  text: resp.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check"
                  });
              }
          });
        },
        reloadData(params) {
            this.params = params;
            console.log(params)
            this.$vs.loading();
            this.$http
                .get("api/v1/kitting-management/data-table", {
                params: {
                    search: params.search,
                    length: params.length,
                    page: params.page,
                    order: params.order,
                    sort: params.sort,
                    territory_id: this.selected.territory.ID,
                    status: 2,
                }
                })
                .then(
                resp => {
                    if (resp.code == 500) {
                    this.$vs.loading.close();
                    } else if(resp.code == 200) {
                    this.$vs.loading.close();
                    this.data = resp.data.itemKits;
                    this.responseData = resp.data;
                    this.checkedAll = false;
                    }
                    else {
                    this.$vs.loading.close();
                    }
                }
                );
        }
    }
    };
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.colored {
    background:antiquewhite;
    background-color: antiquewhite;
}
</style>